module.exports = {
    defaultTitle: 'OBTURA | Productora audiovisual y vídeo en Zaragoza',
    author: 'TheCookies.agency',
    siteUrl: 'https://obtura.tv/', //Esta lo dejo por si se usa en algun sitio pero esta obsoleto
    url: 'https://obtura.tv/',
    logo: 'https://obtura.tv/logo.png',
    legalName: 'OBTURA',
    defaultDescription: 'Obtura Creación Audiovisual somos una productora dedicada al marketing audiovisual. Especializados en vídeo y fotografía trabajamos por todo el mundo.',
    socialLinks: {
        facebook: '',
        twitter: '',
        linkedin: 'https://www.linkedin.com/in/obtura-creaci%C3%B3n-audiovisual-719b4a1b0/',
        instagram: 'https://www.instagram.com/obtura.tv/',
        telegram: '',
        vimeo: '',
        youtube: 'https://www.youtube.com/channel/UCnISEAvydCRkEESN85tqeCg',
        tiktok: '',
        medium: '',
        spotify: '',
        podcast: '',
    },
    social: {
        facebook: '@',
        twitter: '@',
        linkedin: '@',
        instagram: '@',
        telegram: '@',
    },
    address: {
        streetAddress: ' Calle Heroes del Silencio num 17',
        city: 'Zaragoza',
        region: 'Zaragoza',
        country: 'España',
        zipCode: '50008',
    },
    contact: {
        email: 'info@obtura.tv',
        phone: '+34  654 128 299',
        phoneNoSpacing: '+3 654 128 299',
    },
    foundingDate: '2021',
};
